import { useGlobalContext } from "./useGlobalContext"
import { ConfirmationModalProps } from "@/types"

export function useModal() {
  const { state, dispatch } = useGlobalContext()
  const { modalIsOpen, modalOptions } = state

  const closeModal = () => {
    dispatch({
      type: "UPDATE_MODAL_OPTIONS",
      payload: {
        title: "",
        description: "",
        confirmText: "",
        cancelText: "",
        onConfirm: () => {},
      },
    })
    dispatch({ type: "TOGGLE_MODAL", payload: false })
  }

  const openModal = ({
    title,
    description,
    confirmText,
    cancelText,
    onConfirm,
  }: ConfirmationModalProps) => {
    dispatch({
      type: "UPDATE_MODAL_OPTIONS",
      payload: {
        title,
        description,
        confirmText,
        cancelText,
        onConfirm: () => {
          onConfirm()
          closeModal()
        },
      },
    })

    dispatch({ type: "TOGGLE_MODAL", payload: true })
    return
  }

  return { modalOptions, modalIsOpen, openModal, closeModal }
}
