"use client"
import { ReactNode } from "react"
import { SessionProvider } from "next-auth/react"
import { GlobalProvider } from "@/state/GlobalContext"
import Layout from "@/components/Layout"
import { ConfirmationModal } from "@/components/ConfirmationModal"

export const ContextWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <SessionProvider>
      <GlobalProvider>
        <div className="w-full h-full min-h-screen flex flex-col items-center overflow-x-hidden">
          <Layout>{children}</Layout>

          <ConfirmationModal />
        </div>
      </GlobalProvider>
    </SessionProvider>
  )
}
