"use client"
import { ReactNode } from "react"
import { Next13ProgressBar } from "next13-progressbar"

const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <Next13ProgressBar
        height="4px"
        color="#5923f9"
        options={{ showSpinner: false }}
        showOnShallow
      />
    </>
  )
}

export default Providers
